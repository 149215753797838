import {
  create as createProject,
  getById as getProjectById,
  get as getProjects,
  getAndConcat as getProjectsAndConcat,
  update as updateProject
} from '../types/project.js'
import axios from 'axios'

const baseURL = process.env.GATSBY_API_URL

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL
})

export const create = dispatch => project => {
  dispatch({ type: createProject.REQUEST})
  axiosInstance.post('/project', project)
    .then(response => dispatch({type: createProject.SUCCESS, data: response.data}))
    .catch(error => dispatch({type: createProject.FAIL}))
}

export const getById = dispatch => projectId => {
  dispatch({ type: getProjectById.REQUEST})
  axiosInstance.get(`/project/${projectId}`)
    .then(response => dispatch({type: getProjectById.SUCCESS, data: response.data}))
    .catch(error => dispatch({type: getProjectById.FAIL}))
}


export const get = dispatch => queryParams => {
  const queryString = Object.keys(queryParams).reduce((acc, current, index) => `${acc}${index > 0 ? '&': ''}${current}=${queryParams[current]}`, '?')
  dispatch({ type: getProjects.REQUEST})
  axiosInstance.get(`/project${queryString}`)
    .then(response => dispatch({type: getProjects.SUCCESS, data: response.data}))
    .catch(error => dispatch({type: getProjects.FAIL}))
}

export const getAndConcat = dispatch => queryParams => {
  const queryString = Object.keys(queryParams).reduce((acc, current, index) => `${acc}${index > 0 ? '&': ''}${current}=${queryParams[current]}`, '?')
  dispatch({ type: getProjectsAndConcat.REQUEST})
  axiosInstance.get(`/project${queryString}`)
    .then(response => dispatch({type: getProjectsAndConcat.SUCCESS, data: response.data}))
    .catch(error => dispatch({type: getProjectsAndConcat.FAIL}))
}

export const update = dispatch => project => {
  dispatch({ type: updateProject.REQUEST})
  axiosInstance.put('/project', project)
    .then(response => dispatch({type: updateProject.SUCCESS, data: response.data}))
    .catch(error => dispatch({type: updateProject.FAIL}))
}
